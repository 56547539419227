.offer {
  &__attributes-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &-title {
    flex-grow: 1;
  }
}
