.offer-banner {
  white-space: nowrap;
  padding: 3px 6px;
  position: absolute;
  left: -5px;
  top: 63px;
  z-index: 1;
  color: white;
  background-color: orange;
  font-family: "BGMS", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;

  &-triangle {
    position: absolute;
    left: 2px;
    top: -5px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid blue;
    transform: rotate(-135deg);
  }

  &-tag {
    position: static;
    top: auto;
    left: auto;
  }
}
