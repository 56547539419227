html,
body {
  font-family: "BGMS", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.App {
  height: 100%;
  text-align: center;
}

.App * {
  text-decoration: none !important;
}

.App .progress-bar {
  font-size: 25px !important;
  background-color: #e3ea8f;
  color: #646567;
}

.App .progress {
  height: 50px !important;
  vertical-align: middle !important;
  font-size: 25px !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

._loading-overlay {
  position: inherit !important;
}

/* custom */

.card {
  text-align: left !important;
  margin-bottom: 20px;
}

.form-label {
  margin-bottom: 0;
  font-weight: 700;
  padding-top: 5px;
}

.alert p {
  margin-bottom: 0;
}

.form-control-lg {
  font-size: 18px !important;
}

.form-control::placeholder {
  color: #999 !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.form-control[readonly]:focus {
  background-color: #eee;
  color: #555;
}

.form-text, .invalid-feedback {
  font-size: 14px !important;
}

.form-control.is-invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-red) !important;
}

.form-check-input.is-invalid {
  filter: saturate(1);
}

.form-check-label, .form-check-input {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.rdp .rdp-head_cell {
  font-size: 14px !important;
}

.form-select {
  font-size: 14px !important;
}

.btn-block {
  width: 100%;
}

.container.offers form {
  position: relative !important;
}

.container.home {
  padding-bottom: 1px !important;
}

.container.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container.login .login-inner {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 20px 20px 20px;

  border-radius: 10px;
  line-height: 12px !important;
}

@media screen and (max-width: 767px) {
  .container.login {
    justify-content: start;
  }
}

/* media-slider */
.slider-panel svg {
  color: #000;
}

.slider-panel span {
  background-color: #000;
}

.slider-panel span.active {
  background-color: rgb(138, 138, 138);
}

/* video-player */
.video-player .control path,
.event-box .control path {
  fill: #fff;
}

/* video-player progress */
.video-player progress {
  accent-color: rgb(138, 138, 138);
}