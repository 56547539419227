:root {
  --bs-body-font-size: 14px;
  --bs-link-color: #337ab7;
  --bs-link-hover-color: #23527c;
  --bs-border-radius: 6px;
}

.btn {
  --bs-btn-border-radius: 6px;
}

.badge {
  --bs-badge-padding-x: .6em;
  --bs-badge-padding-y: .6em;
  --bs-badge-border-radius: .25em;
}

.bg-success {
  --bs-success-rgb: 92, 184, 92;
  --bs-bg-opacity: 1;
}

.progress {
  --bs-progress-bg: #f5f5f5;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  
}

.form-label {
  margin-bottom: 0.3rem;
}