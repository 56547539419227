.basic-option {
  margin-bottom: 10px;

  &.form-control {
    width: 30%;
    color: #555;
    font-size: 14px;
  }

  &--align-right {
    margin-left: auto;
  }
}

@media (max-width: 420px) {
  .basic-option {
    flex-basis: 100%;
    width: 100%;
  }
}
