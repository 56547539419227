$font-size-base: 0.875rem;
$h4-font-size: 18px;
$h5-font-size: 14px;

$modal-sm: 300px;
$modal-md: 600px;
$modal-lg: 800px;
$modal-xl: 1140px;

@import "../../node_modules/bootstrap/scss/bootstrap";
