.external-offer-variants {
  &__variant-wrapper {
    margin-bottom: 20px;
    list-style: none;
    margin: 0 0 40px 0;
    padding: 0;
  }

  &__icon {
    height: 30px;
    margin-right: 10px;
  }

  &__sort {
    text-align: right;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 420px) {
  .external-offer-variants {
    &__filter {
      flex-wrap: wrap;
    }
  }
}
