.reset {
  .btn-secondary {
    border: 1px solid var(--primary-color);
    background: white;
    color: var(--primary-color);
    transition: all 0.15s;

    &:hover {
      background-color: var(--primary-color);
      color: white;
    }
  }

  .password-separator {
    text-align: center;
  }

  &__support-btn {
    display: none;
  }
}
