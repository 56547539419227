.external-offer-variant {
  padding: 10px 0;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #ccc;

  &:first-of-type {
    border-top: 1px solid #ccc;
  }

  &__variant {
    flex-basis: 25%;

    &--show-details {
      color: var(--primary-color);
      text-align: center;
    }

    &--where {
      text-align: center;
    }

    &--custom {
      text-align: left;
      flex-basis: 50%;
    }

    &--btn {
      text-align: right;
    }
  }

  &__overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__details {
    display: none;
    margin: 0;
    padding: 20px 0 0 0;
    list-style: none;

    &.is-open {
      display: block;
    }
  }

  &__detail {
    &--spacer {
      margin-bottom: 10px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
  }

  &__toggle {
    padding-left: 10px;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      content: "+";
    }

    &.is-open {
      &:before {
        position: absolute;
        left: 0;
        content: "–";
      }
    }
  }
}

@media (max-width: 420px) {
  .external-offer-variant {
    &__overview {
      flex-wrap: wrap;
      height: 60px;
    }

    &__variant {
      &--date,
      &--where {
        flex-basis: 50%;
      }

      &--where {
        text-align: right;
      }

      &--custom {
        flex-basis: 100%;
        text-align: left;
      }

      &--btn {
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}
